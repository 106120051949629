import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { getTagsAction, getTagsSuccessAction, getTagsFailureAction } from './get-tags.actions';
import { GetTagsService } from '../get-tags.service';

@Injectable()
export class getTagsEffects {
  getTags$ = createEffect(() => this.actions$.pipe(
    ofType(getTagsAction),
    switchMap(action =>
      this.getTagsService.get().pipe(
        map(tags => getTagsSuccessAction({ tags })),
        catchError(error => of(getTagsFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private getTagsService: GetTagsService,
  ) {
  }
}
