import { Injectable } from '@angular/core';
import { Observable, switchMap, takeWhile, tap } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  selectCurrentUserCompanyId
} from '../../../current-user/store/current-user.selectors';
import { filter } from 'rxjs/operators';
import { inputIsNotNullOrUndefined } from '../../../data-access/input-is-not-null-or-undefined';
import { Firestore, collection, collectionData } from '@angular/fire/firestore';
import { UserService } from '../../../auth/services/user.service';

export interface Tag {
  id: string,
  name: string,
}

@Injectable({
  providedIn: 'root',
})
export class GetTagsService {
  constructor(private firestore: Firestore, private store$: Store, private userService: UserService) {
  }

  get(): Observable<Tag[]> {
    return <Observable<Tag[]>>this.store$.pipe(
      select(selectCurrentUserCompanyId),
      filter(inputIsNotNullOrUndefined),
      switchMap(companyId =>
        collectionData(collection(this.firestore, `companyTags/${ companyId }/tags`), {
          idField: 'id'
        }).pipe(
          takeWhile(() => !!this.userService.currentUser),
        )
      ),
    );
  }
}
