import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/get-tags.reducers';
import { getTagsEffects } from './store/get-tags.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([getTagsEffects]),
    StoreModule.forFeature('getTags', reducer),
  ],
})
export class GetTagsModule {}
