import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/create-tag.reducers';
import { CreateTagEffects } from './store/create-tag.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([CreateTagEffects]),
    StoreModule.forFeature('createTag', reducer),
  ],
})
export class CreateTagModule {}
