import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CreateTagService } from '../create-tag.service';
import { createTagAction, createTagSuccessAction, createTagFailureAction } from './create-tag.actions';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class CreateTagEffects {
  createTag$ = createEffect(() => this.actions$.pipe(
    ofType(createTagAction),
    switchMap(action =>
      this.createTagService.create(action.payload).pipe(
        map(data => createTagSuccessAction({ data })),
        catchError(error => of(createTagFailureAction({ error }))),
      )
    )
  ));

  createTagSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(createTagSuccessAction),
    tap(() => this.notifyService.success('The tag successfully created')),
  ), { dispatch: false });

  createTagFailure$ = createEffect(() => this.actions$.pipe(
    ofType(createTagFailureAction),
    tap(() => this.notifyService.error('Failure to create tag')),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private createTagService: CreateTagService,
    private notifyService: ToastrService,
  ) {
  }
}
