import { Action, createReducer, on } from '@ngrx/store';
import {
  getTagsAction, getTagsSuccessAction, getTagsFailureAction,
} from './get-tags.actions';
import { GetTagsState } from './get-tags.types';

const initialState = {
  loading: false,
  loaded: false,
  tags: [],
  error: null,
};

const getTagsReducer = createReducer<GetTagsState>(
  initialState,
  on(
    getTagsAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),
  on(
    getTagsSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      tags: action.tags,
      error: null,
    })
  ),
  on(
    getTagsFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: GetTagsState, action: Action) => getTagsReducer(state, action);
