import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import {
  selectCurrentUserCompanyId
} from '../../../current-user/store/current-user.selectors';
import { inputIsNotNullOrUndefined } from '../../../data-access/input-is-not-null-or-undefined';
import { Firestore, addDoc, collection, doc, docData } from '@angular/fire/firestore';

export interface CreateTagPayload {
  name: string,
}

@Injectable({
  providedIn: 'root',
})
export class CreateTagService {
  constructor(private firestore: Firestore, private store$: Store) {
  }

  create(payload: CreateTagPayload): Observable<any> {
    const document = {...payload};
    return this.store$.pipe(
      select(selectCurrentUserCompanyId),
      filter(inputIsNotNullOrUndefined),
      switchMap(companyId =>
        addDoc(collection(this.firestore, `companyTags/${companyId}/tags`), document),
      ),
      switchMap(document => docData(doc(this.firestore, document.path), {idField: 'id'})),
      take(1),
    );
  }
}
